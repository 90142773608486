import React, {useContext, useEffect} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import {getString} from "../../js/languages";
import {Navigate} from "react-router-dom";
import {apiFetch} from "../../js/util";
import {UserContext, UserContextType} from "../../components/App/App";

const Logout: React.FC = () => {
    const [done, setDone] = React.useState(false);
    const [, setUser] = useContext(UserContext) as UserContextType;

    useEffect(() => {
        apiFetch("/user/logout", {method: "POST", credentials: "include"})
            .then(res => {
                setUser(undefined);
                setDone(true);
            })
            .catch(err => {
                console.log(err);
                setDone(true);
            });
        setTimeout(() => setDone(true), 2000);
    }, [setUser]);

    return (
        <div id={"page"} className={"bg-background-primary"}>
            <Navbar/>

            <div id={"content"} className={"content-wrap p-5"}>
                <div className="mx-auto text-center bg-primary text-txt-primary px-3 py-5 rounded">
                    {!done ? <h1>{getString("ongoingLogout")}</h1> : <Navigate to={"/"}/>}
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default Logout;