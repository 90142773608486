import React from 'react';
import "./Footer.css";

const Footer: React.FC = () => {
    return (
        <footer id={"footer"} className="bg-gray-800 text-white p-4 text-center hidden">
            <p>&copy; {new Date().getFullYear()} Inventory | Website by Nico Schreibmaier</p>
        </footer>
    );
};

export default Footer;