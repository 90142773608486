import React, {useEffect, useState} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './Home.css';
import ItemModal from "./components/ItemModal";
import ItemCategory from "./components/ItemCategory";
import {Item as ItemObj} from "../../../server/routes/items";
import {Category as CategoryObj} from "../../../server/routes/categories";
import {apiFetch, groupArrByKeys} from "../../js/util";
import AddItemModal from "./components/AddItemModal";
import AddItemCategoryModal from "./components/AddItemCategoryModal";
import {useSearchParams} from "react-router-dom";
import {getString} from "../../js/languages";

// - Components - //
export type GroupedItems = { [itemName: string]: ItemObj[] }
export type ItemStructure = { [categorId: string]: GroupedItems }

const Home: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState<string>();
    const [items, setItems] = useState<ItemObj[]>([]);
    const [categories, setCategories] = useState<CategoryObj[]>([]);

    const searchInput = (e: React.FormEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value.toLowerCase();
        setSearchTerm(val.length > 0 ? val : undefined);
    }

    useEffect(() => {
        Promise.all([
            apiFetch("/items/area/MQ==", {method: "GET", credentials: "include"})
                .then(res => res.json()),
            apiFetch("/categories", {method: "GET", credentials: "include"})
                .then(res => res.json())
        ])
            .then(([rawItems, rawCategories]) => {
                setItems(rawItems);
                setCategories(rawCategories);
            })
            .catch(error => console.log(error));
    }, []);

    // Filter items by search term
    let filteredItems: ItemObj[];
    if (searchTerm) {
        filteredItems = items.filter(item => item.name.toLowerCase().includes(searchTerm));
    } else filteredItems = items;

    const itemStructure: ItemStructure = groupArrByKeys(filteredItems, ["category", "name"]);
    const modalItem = searchParams.get("item");
    const modalCategory = searchParams.get("category");

    return (
        <div id={"page"} className={"bg-gray-700"}>
            <Navbar/>

            {
                (modalItem && modalCategory) &&
                <ItemModal children={itemStructure[modalCategory]?.[modalItem] || []} useItems={[items, setItems]}/>
            }

            <div className={"m-3 text-center"}>
                <AddItemModal useItems={[items, setItems]} useCategories={[categories, setCategories]}/>
                <AddItemCategoryModal useCategories={[categories, setCategories]}/>
            </div>

            <label className={"text-center text-white mt-1 mb-2"}>{getString("searchItems")}</label>
            <input placeholder={getString("searchTerm")} className={"w-1/2 lg:w-1/4 mx-auto text-center rounded-md p-0.5"}
                   onInput={searchInput}/>

            <div id={"content"} className={"p-5"}>
                <div className={"columns-1 gap-2 lg:columns-2 xl:columns-3 xxl:columns-4"}>
                    {categories.map(category => itemStructure[category.id] &&
                        <ItemCategory category={category} children={itemStructure[category.id]} key={category.id}
                                      useItems={[items, setItems]} useCategories={[categories, setCategories]}/>
                    )}
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default Home;