import React, {useContext, useRef} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import {UserContext, UserContextType} from "../../components/App/App";
import {Navigate, useLocation} from "react-router-dom";
import {getString} from "../../js/languages";
import {apiFetch, parseForm} from "../../js/util";
import MsgBox, {Message} from "../../components/MsgBox/MsgBox";

const Login: React.FC = () => {
    const [msg, setMsg] = React.useState<Message>();
    const [user] = useContext(UserContext) as UserContextType;
    const {state} = useLocation();
    const form = useRef<HTMLFormElement>(null);

    const prevPath = state?.prevPath || "/";
    if (user) return <Navigate to={prevPath}/>;

    const submit = () => {
        if (!form.current) return;
        const {username, password} = parseForm(form.current);

        setMsg(undefined);
        apiFetch("/user/login", {
            method: "POST",
            headers: {
                Authorization: "Basic " + btoa(username + ":" + password)
            },
            credentials: "include"
        })
            .then(async (value) => {
                switch (value.status) {
                    case 200:
                        setMsg(["success", "Erfolgreich eingeloggt!"]);
                        setTimeout(() => window.location.reload(), 1000);
                        break;
                    case 401:
                        setMsg(["error", "Der Benutzername oder das Passwort ist falsch."]);
                        break;
                    default:
                        setMsg(["error", "Beim Einloggen ist ein Fehler aufgetreten: " + value.status]);
                        console.error(value);
                }
            })
    }

    return (
        <div id={"page"} className={"bg-background-primary"}>
            <Navbar/>

            <div id={"content"} className={"content-wrap p-5"}>
                <MsgBox msgState={[msg, setMsg]} hideAfter={3000}/>

                <div className="w-full lg:w-3/4 mx-auto text-center bg-primary text-txt-primary px-5 py-5 rounded">
                    <h1>{getString("pageLogin")}</h1>

                    <br/>

                    <div className={"w-full md:w-3/4 max-w-xl mx-auto"}>
                        <form ref={form} className={"flex justify-center flex-col space-y-2 mb-2"}>
                            <input type={"text"} name={"username"} placeholder={getString("username")} required={true}/>
                            <input type={"password"} name={"password"} placeholder={getString("password")}
                                   required={true}/>
                        </form>

                        <button onClick={submit}
                            className={"bg-secondary rounded-md px-4 py-1 hover:bg-secondary-alt"}>
                            {getString("submit")}</button>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default Login;