import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {mapping, UserContext, UserContextType} from "../App/App";
import Icon from "@mdi/react";
import {mdiMenu, mdiMenuClose} from "@mdi/js";
import {Link} from "react-router-dom";
import {getString} from "../../js/languages";

const Navbar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [userDropdownOpen, setUserDropdownOpen] = React.useState(false);
    const [user] = useContext(UserContext) as UserContextType;

    const userDropdownBtn = useRef<HTMLButtonElement>(null);
    const userMenu = useRef<HTMLDivElement>(null);

    const handleClick = useCallback((e: MouseEvent) => {
        const btn = userDropdownBtn.current;
        const menu = userMenu.current;

        if (btn?.contains(e.target as Element)) {
            setUserDropdownOpen(!userDropdownOpen);
        } else if (!userDropdownOpen || !menu?.contains(e.target as Element)) {
            setUserDropdownOpen(false);
        }
    }, [userDropdownOpen, userMenu]);

    useEffect(() => {
        window.addEventListener("click", handleClick);
        return () => {
            window.removeEventListener("click", handleClick);
        }
    }, [handleClick]);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const currentLocation = window.location.pathname;
    const filteredMapping = mapping.filter(v => v.name && // Check if hidden
        (v.permissionLevel === undefined || (user && user.permission_level >= v.permissionLevel)) // Check if permitted to see
    );

    return (
        <nav className={"bg-primary text-txt-primary"}>
            <div className={"mx-auto px-3"}>
                <div className="flex items-center relative h-14">
                    <div className={"flex items-center sm:hidden left-0"}>
                        <button onClick={toggle} className={"relative"}>
                            {!isOpen ?
                                <Icon path={mdiMenu} size={1} className={"block"}/>
                                :
                                <Icon path={mdiMenuClose} size={1} className={"block"}/>
                            }
                        </button>
                    </div>

                    <div className={"flex items-center justify-center"}>
                        <div className={"flex flex-shrink-0 items-center mx-2"}>
                            { /*Icon Here*/ }
                        </div>
                        <div className={"hidden sm:block"}>
                            <div className={"flex space-x-2"}>
                                {filteredMapping.map(v => v.path === currentLocation ?
                                    <Link to={v.path} state={{prevPath: window.location.pathname}} aria-current={"page"}
                                          key={v.path}
                                          className={"rounded-md bg-primary-alt px-3 py-2 text-sm font-medium text-txt-primary"}>
                                        {v.name}
                                    </Link> :
                                    <Link to={v.path} state={{prevPath: window.location.pathname}} key={v.path}
                                          className={"rounded-md px-3 py-2 text-sm font-medium text-txt-primary hover:bg-background-primary hover:text-txt-primary"}>
                                        {v.name}
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={"absolute right-0 mx-2"}>
                        <div className={"relative"}>
                            {!user ? <Link to={"/login"} state={{prevPath: window.location.pathname}}
                                           className={"bg-secondary rounded-md px-2 py-1 hover:bg-secondary-alt"}>
                                {getString("login")}
                            </Link> : <div id={"navbar-user"} ref={userMenu}>
                                <div id={"navbar-user-icon"}>
                                    <button ref={userDropdownBtn}
                                            className={"rounded-full bg-background-primary size-8 inline-flex items-center justify-center text-xs"}>
                                        {user.firstname[0]}{user.lastname[0]}
                                    </button>
                                </div>

                                {userDropdownOpen ?
                                    <div id={"navbar-user-dropdown"}
                                         className={"absolute right-0.5 bg-background-secondary rounded text-txt-secondary py-2 mt-0.5"}>
                                        <p className={"px-2"}>{user.firstname} {user.lastname}</p>

                                        <hr className={"w-full my-2 text-background-secondary-alt"}/>

                                        <Link to={"/logout"} state={{prevPath: window.location.pathname}}
                                              className={"px-2 py-0.5 block w-full text-left hover:bg-background-secondary-alt"}>Logout
                                        </Link>
                                    </div> : null}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

            <div className={!isOpen ? "hidden" : "sm:hidden"}>
                <div className={"space-y-1 px-2 pb-3 pt-2"}>
                    {filteredMapping.map(v => v.path === currentLocation ?
                        <Link to={v.path} state={{prevPath: window.location.pathname}} key={v.path}
                              className="block rounded-md bg-primary-alt px-3 py-2 text-base font-medium text-txt-primary"
                              aria-current="page">
                            {v.name}
                        </Link> :
                        <Link to={v.path} state={{prevPath: window.location.pathname}} key={v.path}
                              className="block rounded-md px-3 py-2 text-base font-medium text-txt-primary hover:bg-background-primary hover:text-txt-primary">
                            {v.name}
                        </Link>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;