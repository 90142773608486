import React, {createContext, useEffect, useState, Dispatch, ReactNode} from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import NotFound from "../../pages/NotFound/NotFound";
import Home from "../../pages/Home/Home";
import ShoppingList from "../../pages/ShoppingList/ShoppingList";
import {apiFetch} from "../../js/util";
import {PublicUser} from "../../../server/routes/user";
import Login from "../../pages/Login/Login";
import Logout from "../../pages/Logout/Logout";
import Unauthorized from "../../pages/Unauthorized/Unauthorized";
import Loading from "../Loading/Loading";
import Footer from "../Footer/Footer";
import {getString} from "../../js/languages";

export const UserContext = createContext<UserContextType | null>(null);
export type UserContextType = [PublicUser | undefined, Dispatch<React.SetStateAction<PublicUser | undefined>>];

export type MappingPath = {
    path: string,
    name?: string, // leave empty to hide it from navbar
    permissionLevel?: number,
    element: ReactNode
}

export const mapping = [
    {path: "/", name: getString("home"), permissionLevel: 0, element: <Home/>},
    {path: "/shopping-list", name: getString("shoppingList"), permissionLevel: 0, element: <ShoppingList/>},
    {path: "/logout", permissionLevel: 0, element: <Logout/>},
    {path: "/login", element: <Login/>},
] as MappingPath[];

const App: React.FC = () => {
    const userState = useState<PublicUser>();
    const [user, setUser] = userState;
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        apiFetch("/user", {credentials: "include"})
            .then(res => res.json())
            .then((res: PublicUser) => {
                setUser(res);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, [setUser, setLoading]);

    let router = (<Router>
        <Routes>
            {mapping.map(v => v.permissionLevel === undefined || (user && user.permission_level >= v.permissionLevel) ?
                <Route path={v.path} element={v.element} key={v.path}/> :
                <Route path={v.path} element={<Unauthorized/>} key={v.path}/>
            )}

            <Route path="*" element={<NotFound/>}/>
        </Routes>
    </Router>)

    return (
        <UserContext.Provider value={userState}>
            {!loading ? router : <div id={'page'} className={"bg-background-primary"}>
                <div id={"content"} className={"flex justify-center items-center text-white"}>
                    <Loading/>
                </div>

                <Footer/>
            </div>}
        </UserContext.Provider>
    );
}

export default App;