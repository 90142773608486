import React, {useEffect, useRef, useState} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './ShoppingList.css';
import {getString} from "../../js/languages";
import {apiFetch} from "../../js/util";
import {RequestShoppingListItem} from "../../../server/routes/shoppinglist";
import Icon from "@mdi/react";
import {mdiMinus, mdiPlus, mdiTrashCan} from "@mdi/js";

const ShoppingList: React.FC = () => {
    const [list, setList] = useState<RequestShoppingListItem[]>([]);
    const [itemNames, setItemNames] = useState<string[]>([]);
    const itemNameInput = useRef<HTMLInputElement>(null);

    const decreaseItem = (e: React.MouseEvent<HTMLSpanElement>) => {
        const id = e.currentTarget.dataset.id
        if (!id) return;

        let clone = [...list];
        const index = clone.findIndex(v => v.id === id);
        const entry = clone[index]
        if (!entry) return;

        if (entry.amount > 1) {
            entry.amount -= 1;
        } else {
            clone = clone.filter(v => v.id !== id);
        }

        setList(clone);
    }

    const increaseItem = (e: React.MouseEvent<HTMLSpanElement>) => {
        const id = e.currentTarget.dataset.id;
        if (!id) return;

        const clone = [...list];
        const index = clone.findIndex(v => v.id === id);
        const entry = clone[index]
        if (!entry) return;

        entry.amount += 1;

        setList(clone);
    }

    const deleteItem = (e: React.MouseEvent<HTMLSpanElement>) => {
        const id = e.currentTarget.dataset.id;
        if (!id) return;

        const index = list.findIndex(v => v.id === id);
        const entry = list[index]
        if (!entry) return;

        apiFetch("/shopping-list/" + id, {method: "DELETE", credentials: "include"})
            .then(res => res.ok ?  setList(list.filter(i => i.id !== id)) : null);
    }

    const clearList = () => {
        apiFetch("/shopping-list", {method: "DELETE", credentials: "include"})
            .then(res => {
                if (!res.ok) return;

                const newList = [];
                for (const item of list) {
                    if (item.item) newList.push(item);
                }
                setList(newList);
            });
    }

    const addItem = () => {
        const name = itemNameInput.current?.value;
        if (!name) return;

        apiFetch("/shopping-list/force-add", {method: "POST", body: {name: name}, credentials: "include"})
            .then(res => res.json())
            .then((item: RequestShoppingListItem) => {
                setList([...list, {
                    id: item.id,
                    name: item.name,
                    amount: item.amount,
                }]);
            })
    }

    useEffect(() => {
        apiFetch("/shopping-list", {method: "GET", credentials: "include"})
            .then(res => res.json())
            .then(data => setList(data))
            .catch(error => console.log(error));

        apiFetch("/items/list-names", {method: "GET", credentials: "include"})
            .then(res => res.json())
            .then(data => setItemNames(data))
            .catch(error => console.log(error));
    }, []);

    return (
        <div id={"page"} className={"bg-background-primary text-white"}>
            <Navbar/>

            <div id={"content"} className={"content-wrap p-5"}>
                <h1 className={"mt-0 mb-4 text-2xl text-center"}>{getString("shoppingList")}</h1>

                <div className={"text-center my-4"}>
                    <button className={"py-1 px-2 rounded-md ml-2 bg-red-500 hover:bg-red-700"} onClick={clearList}>
                        {getString("clearShoppingList")}
                    </button>
                </div>

                <p className={"text-center mb-2"}>{getString("addToShoppingList")}</p>

                <div className={"flex justify-center mb-5"}>
                    <input type={"text"} className={"w-1/2 lg:w-1/4 rounded-md text-black px-2 py-1"} list={"itemNames"}
                           placeholder={getString("name")} ref={itemNameInput}/>

                    <button className={"py-1 px-2 rounded-md ml-2 bg-green-500"}
                            onClick={addItem}>{getString("add")}</button>

                    {itemNames.length > 0 && <datalist id={"itemNames"}>
                        {itemNames.map((name, index) => <option value={name} key={index}/>)}
                    </datalist>}
                </div>

                <div className="w-full md:w-3/4 mx-auto text-black">
                    <table className={"w-full"}>
                        <thead>
                        <tr>
                            <th className={"w-2/12"}>Category</th>
                            <th className={"w-6/12"}>Name</th>
                            <th className={"w-2/12 text-center"}>Amount</th>
                            <th className={"w-2/12 text-center"}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {list.map(item => <tr key={item.id}>
                            <td>{item.item?.category[1]}</td>
                            <td>{item.name}</td>
                            <td className={"text-center"}>{item.amount}</td>
                            <td className={"flex justify-center"}>
                                <span onClick={increaseItem} data-id={item.id}>
                                    <Icon path={mdiPlus} size={1} className={"cursor-pointer"}
                                          title={"+1"}/>
                                </span>
                                <span onClick={decreaseItem} data-id={item.id}>
                                    <Icon path={mdiMinus} size={1} className={"cursor-pointer"}
                                          title={"-1"}/>
                                </span>
                                <span onClick={deleteItem} data-id={item.id}>
                                    <Icon path={mdiTrashCan} size={1} className={"cursor-pointer"}
                                          title={getString("delete")}/>
                                </span>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default ShoppingList;