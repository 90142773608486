import React, {useEffect, useState} from 'react';
import Modal from "../../../components/Modal/Modal";
import {apiFetch, dateFormat} from "../../../js/util";
import Icon from "@mdi/react";
import {mdiDelete} from "@mdi/js";
import {getString} from "../../../js/languages";
import {Item as ItemObj} from "../../../../server/routes/items";
import {useSearchParams} from "react-router-dom";
import EditableString from "../../../components/Input/EditableString/EditableString";
import {RequestShoppingListItem} from "../../../../server/routes/shoppinglist";

interface Props {
    children: ItemObj[],
    useItems: [ItemObj[], React.Dispatch<React.SetStateAction<ItemObj[]>>]
}

const ItemModal: React.FC<Props> = ({children, useItems}) => {
    const [items, setItems] = useItems;
    const [linkedShoppingListItem, setLinkedShoppingListItem] = useState<RequestShoppingListItem>();
    const [, setSearchParams] = useSearchParams();

    const {name, category} = children?.[0] || {};
    useEffect(() => {
        if (!name || !category) return;
        apiFetch("/shopping-list/get-linked-item", {query: {name: name, category: category}, credentials: "include"})
            .then(res => res.json())
            .then(json => json.item && setLinkedShoppingListItem(json.item))
            .catch(() => {
            });
    }, [category, name]);

    if (children.length === 0) return null;

    const close = () => {
        setSearchParams();
    }

    const updateAmount = async (val: string) => {
        const amount = parseInt(val);
        if (!amount && amount !== 0) return false;

        let res: boolean = true;
        apiFetch("/shopping-list/set-linked-item-amount", {
            method: "POST",
            query: {name: name, category: category, amount: amount},
            credentials: "include"
        }).catch((err) => {
            console.error(err);
            res = false;
        });

        return res;
    }

    const doDeleteItem = (e: React.MouseEvent<HTMLSpanElement>) => {
        const id = e.currentTarget.dataset.id;
        if (!id) return;

        const confirmed = window.confirm("Are you sure you want to delete the item #" + id + "?");
        if (!confirmed) return;

        apiFetch("/items/" + id, {method: "DELETE", credentials: "include"})
            .then(res => res.ok && setItems(items.filter(v => v.id !== id)))
            .catch(err => console.log(err));
    }

    return (
        <Modal isOpen={true} onClose={close}>
            <h1 className={"text-center text-2xl"}>{name}</h1>
            <h2 className={"text-center text-lg"}>{getString("itemCount", children.length)}</h2>

            <div className={"mb-5 mt-2"}>
                <p className={"text-center mb-0.5"}>{getString("minSupply")}:</p>
                <EditableString inputClassName={"!bg-gray-100"} className={"justify-center"} onSave={updateAmount}
                                value={linkedShoppingListItem?.amount?.toString() || "0"}/>
            </div>

            <table className={"min-w-full divide-y divide-gray-200"}>
                <thead>
                <tr>
                    <th className={"px-6 py-3 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase"}>{getString("id")}</th>
                    <th className={"px-6 py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase"}>{getString("expiry")}</th>
                    <th className={"px-6 py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase"}>{getString("actions")}</th>
                </tr>
                </thead>

                <tbody className={"bg-gray-50 divide-y divide-gray-200"}>
                {
                    children.map((item, index) =>
                        <tr className={Date.now() > item.expiry && item.expiry > 0 ? "bg-red-300" : ""} key={item.id}>
                            <td className={"px-6 py-4 whitespace-no-wrap"}>{item.id}</td>
                            <td className={"px-6 py-4 whitespace-no-wrap"}>
                                {item.expiry > 0 ? dateFormat(new Date(item.expiry)) : getString("na")}
                            </td>
                            <td className={"px-6 py-4 whitespace-no-wrap flex justify-center"}>
                                <span onClick={doDeleteItem} data-id={item.id}>
                                    <Icon path={mdiDelete} size={1} className={"cursor-pointer"}
                                          title={getString("delete")}/>
                                </span>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </Modal>
    );
}

export default React.memo(ItemModal);