import React, {createRef, useEffect, useState} from 'react';
import Icon from "@mdi/react";
import {mdiContentSave, mdiPencil} from "@mdi/js";

interface Props {
    className?: string,
    inputClassName?: string,
    onSave?: (val: string) => boolean | Promise<boolean>,
    value?: string,
}

const EditableString: React.FC<Props> = ({className, onSave, inputClassName, value}) => {
    const [currentValue, setCurrentValue] = useState<string>(value || "");
    const [editMode, setEditMode] = useState<boolean>(false);
    const inputRef = createRef<HTMLInputElement>();

    useEffect(() => {
        if (value) setCurrentValue(value);
    }, [value]);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    }

    const doSave = async () => {
        if (onSave) {
            const res = await onSave(currentValue);
            if (res) toggleEditMode();
        } else {
            toggleEditMode();
        }
    }

    return (
        <div className={"flex items-center" + (className ? ` ${className}` : "")}>
            <div className={"relative text-black"}>
                <input type="text" ref={inputRef} value={currentValue}
                       className={"p-1 pr-7 rounded disabled:bg-white disabled:text-gray-400" + (inputClassName ? ` ${inputClassName}` : "")}
                       disabled={!editMode} onInput={(e) => setCurrentValue(e.currentTarget.value)}/>
                {!editMode ? <span onClick={toggleEditMode} className={"has-pointer-on-hover"}>
                     <Icon path={mdiPencil} size={1} color={"black"} className={"absolute top-1 right-1"}/>
                </span> : null}
            </div>

            {editMode ? <button
                className={"ml-1.5 bg-green-500 p-0.5 rounded hover:bg-green-600"} onClick={doSave}>
                <Icon path={mdiContentSave} color={"white"} size={1}/>
            </button> : null}
        </div>
    );
};

export default EditableString;
