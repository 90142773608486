import React from 'react';
import './Modal.css';

interface Props {
    isOpen: boolean,
    onClose: () => void,
    className?: string,
    closePosition?: "inside" | "outside"
}

const Modal: React.FC<React.PropsWithChildren<Props>> = ({isOpen, onClose, children, className, closePosition}) => {
    if (!isOpen) return null;

    closePosition = !closePosition ? "inside" : closePosition;
    className = className ? " " + className : "";

    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className={"bg-white p-6 rounded-lg" + className + (closePosition === "inside" ? " relative" : "")}>
                <button
                    className={closePosition === "inside" ? "absolute right-4 top-4" : "absolute top-2 right-2 text-white"}
                    onClick={onClose}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </button>
                {children}
            </div>
        </div>
    );
};

export default Modal;