export type LanguageShort = keyof typeof languages;
type Language = typeof fallback;

export type LanguageStringId = {
    [K in keyof Language]: Language[K] extends string ? K : never // List all keys with string values
}[keyof Language];

export type LanguageVarId = {
    [K in keyof Language]: Language[K] extends string ? never : K // List all keys with non-string values
}[keyof Language];

const languages = {
    en: {
        // [Generic] //
        add: "Add",
        delete: "Delete",
        submit: "Submit",
        show: "Show",
        confirm: "Confirm",
        login: "Login",
        logout: "Logout",
        ongoingLogout: "You are being logged out...",
        dateFormat: "yyyy-MM-dd",
        settings: "Settings",
        deleteConfirmation: "Do you really want to delete '%s'?",
        list: "Liste",
        key: "Key",
        value: "Value",
        string: "String",
        save: "Save",
        genericError: "Something went wrong: %s",
        genericSuccess: "Operation successful!",
        overview: "Overview",
        verify: "Verify",
        username: "Username",
        password: "Password",
        description: "Description",
        csvFile: "CSV File",
        delimiter: "CSV Delimiter",

        // [Navigation] //
        pageNotFound: "Page not found",
        pageUnauthorized: "Unauthorized",
        pageUnauthorizedDesc: "You are not authorized to see this page.",
        pageLogin: "Login",
        backToLastLocation: "Back to last location",
        home: "Home",

        // [Pages]  //
        // Home Page
        itemAdd: "Add Item",
        itemName: "Item Name",
        itemCategory: "Item Category",
        categoryAdd: "Add Category",
        categoryName: "Category Name",
        name: "Name",
        amount: "Amount",
        needed: "Needed",
        id: "ID",
        expiry: "Expiry",
        expiryDate: "Expiry Date",
        area: "Area",
        actions: "Actions",
        itemCount: "%s items",
        remove: "Remove",
        deleteItemConfirmation: "Do you really want to remove item '%s' with the expiry date '%s'?",
        na: "N/A",
        minSupply: "Minimum supply of item",
        searchItems: "Search Items",
        searchTerm: "Search Term",

        // Shopping List Page
        shoppingList: "Shopping List",
        addToShoppingList: "Add item to shopping list",
        clearShoppingList: "Clear Shopping List",

        // [Variables] //
        MONTHS: [
            "January", "February", "March", "April", "May", "June", "July", "August", "September", "October",
            "November", "December"
        ],
        MONTHS_SHORT: [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ],
        WEEKDAYS: [
            "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"
        ],
        WEEKDAYS_SHORT: [
            "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"
        ]
    },

    de: {
        // [Generic] //
        add: "Hinzufügen",
        delete: "Löschen",
        submit: "Absenden",
        show: "Anzeigen",
        confirm: "Bestätigen",
        login: "Einloggen",
        logout: "Ausloggen",
        ongoingLogout: "Sie werden ausgeloggt...",
        dateFormat: "dd.MM.yyyy",
        settings: "Einstellungen",
        deleteConfirmation: "Wollen Sie '%s' wirklich löschen?",
        list: "Liste",
        key: "Schlüssel",
        value: "Wert",
        string: "Text",
        save: "Speichern",
        genericError: "Etwas ist schiefgelaufen: %s",
        genericSuccess: "Operation erfolgreich!",
        overview: "Überblick",
        verify: "Verify",
        username: "Benutzername",
        password: "Passwort",
        description: "Beschreibung",
        csvFile: "CSV Datei",
        delimiter: "CSV Trennzeichen",

        // [Navigation] //
        pageNotFound: "Seite nicht gefunden",
        pageUnauthorized: "Nicht berechtigt",
        pageUnauthorizedDesc: "Sie sind nicht dazu berechtigt, diese Seite aufzurufen.",
        pageLogin: "Login",
        backToLastLocation: "Zurück zur letzten Seite",
        home: "Startseite",

        // [Pages] //
        // Home Page
        itemAdd: "Produkt hinzufügen",
        itemName: "Produktname",
        itemCategory: "Produktkategorie",
        categoryAdd: "Kategorie hinzufügen",
        categoryName: "Kategorie Name",
        name: "Name",
        amount: "Anzahl",
        needed: "Notwendig",
        id: "ID",
        expiry: "Ablaufdatum",
        expiryDate: "Ablaufdatum",
        area: "Bereich",
        actions: "Aktionen",
        itemCount: "%s Produkte",
        remove: "Entfernen",
        deleteItemConfirmation: "Möchtest du das Produkt '%s' mit dem Ablaufdatum '%s' wirklich löschen?",
        na: "k.A.",
        searchItems: "Inventar Durchsuchen",
        searchTerm: "Suchbegriff",
        minSupply: "Mindestanzahl auf Lager",

        // Shopping List Page
        shoppingList: "Einkaufsliste",
        addToShoppingList: "Produkt zur Einkaufsliste hinzufügen",
        clearShoppingList: "Einkaufsliste leeren",

        // [Variables] //
        // Format: [displayText, placeholder, description]
        MONTHS: [
            "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober",
            "November", "Dezember"
        ],
        MONTHS_SHORT: [
            "Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"
        ],
        WEEKDAYS: [
            "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"
        ],
        WEEKDAYS_SHORT: [
            "Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"
        ]
    }
};

const fallback = languages.en;

export function getString(id: LanguageStringId, ...replace: any[]): string {
    const userLang = navigator.language?.split("-")?.[0] as LanguageShort;
    const lang = languages[userLang] || {};
    let langString = lang[id] || fallback[id] || `{${id}}`;

    if (replace) {
        for (const val of replace) {
            langString = langString
                .replace("%s", val)
                .replace("%d", val)
        }
    }

    return langString;
}

export function getVariable(id: LanguageVarId): any | null {
    const userLang = navigator.language?.split("-")?.[0] as LanguageShort;
    const lang: Language = languages[userLang] || fallback;
    const variable: any = lang[id];

    return variable !== undefined ? variable : null;
}
