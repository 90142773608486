import React from 'react';
import Modal from "../../../components/Modal/Modal";
import {Category as CategoryObj, RequestCategory} from "../../../../server/routes/categories";
import {apiFetch, parseForm} from "../../../js/util";
import {getString} from "../../../js/languages";

interface Props {
    useCategories: [CategoryObj[], React.Dispatch<React.SetStateAction<CategoryObj[]>>]
}

interface FormData {
    name?: string
}

const AddItemCategoryModal: React.FC<Props> = ({ useCategories}) => {
    const [modal, setModal] = React.useState(false);
    const [categories, setCategories] = useCategories;

    function addCategory() {
        const form = document.getElementById("addCategory") as HTMLFormElement;
        if (!form) return;

        const data: FormData = parseForm(form);
        if (!data.name) return;

        const category: RequestCategory = {
            name: data.name,
        }

        apiFetch("/categories", {method: "POST", body: category, credentials: "include"})
            .then(res => res.json())
            .then(data => {
                if (!data.success || !data.id) return;
                category.id = data.id;
                setCategories([...categories, category as CategoryObj]);
            })
            .catch(error => console.log(error));
    }

    return (
        <>
            <button className={"bg-white rounded-md p-1 mx-1"} onClick={() => setModal(true)}>
                {getString("categoryAdd")}
            </button>

            <Modal isOpen={modal} onClose={() => setModal(false)} className={"lg:w-1/6"}>
                <h1 className={"mt-0 mb-4 text-2xl text-center"}> {getString("categoryAdd")}</h1>

                <form id={"addCategory"} className={"block"}>
                    <label htmlFor="addCategoryName" className={"select-none"}>{getString("categoryName")}</label>
                    <input type={"text"} placeholder={"Name"} id={"addCategoryName"} name={"name"}
                           className={"border rounded p-1 block mb-2 w-full"}/>

                    <input type={"button"} value={getString("add")} onClick={addCategory}
                           className={"bg-green-400 rounded-md w-full text-white p-1 mt-2 cursor-pointer"}/>
                </form>
            </Modal>
        </>
    );
};

export default AddItemCategoryModal;