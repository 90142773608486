import {getString} from "./languages";

const DEBUG = true;

const months = [
    "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober",
    "November", "Dezember"
];
const monthsShort = [
    "Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"
];
const weekdays = [
    "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"
];
const weekdaysShort = [
    "Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"
];

// --- //

type Dict<val = any> = { [key: string]: val };
export type DataGroup<val = any> = {group: string, elements: Dict<val>[]};
type RequestOptions = Omit<RequestInit, "body"> & { body?: Dict | any[] | string };

export function numberFitMinLength(n: number | string, length: number): string {
    const str = typeof (n) === "number" ? n.toString() : n;
    return str.length >= length ? str : str.padStart(length, "0");
}

export function dateFormat(date: Date, format?: string): string {
    if (!format) format = getString("dateFormat");

    return format
        .replace("dd", numberFitMinLength(date.getDate(), 2))
        .replace("MMMM", months[date.getMonth()])
        .replace("MMM", monthsShort[date.getMonth()])
        .replace("MM", numberFitMinLength(date.getMonth() + 1, 2))
        .replace("yyyy", date.getFullYear().toString())
        .replace("yy", date.getFullYear().toString().substring(2, 4))
        .replace("hh", numberFitMinLength(date.getHours(), 2))
        .replace("mm", numberFitMinLength(date.getMinutes(), 2))
        .replace("ss", numberFitMinLength(date.getSeconds(), 2))
        .replace("wwww", weekdays[date.getDay()])
        .replace("ww", weekdaysShort[date.getDay()])
}

export function parseForm(form: HTMLFormElement): Dict {
    const data: Dict = {};

    for (let i = 0; i < form.length; i++) {
        let el = form.elements[i] as (HTMLInputElement | HTMLSelectElement);
        if (el.tagName !== "INPUT" && el.tagName !== "SELECT") continue;

        let name = el.name;
        if (!name && el.labels && el.labels[0]) name = el.labels[0].innerText;
        else if (!name) continue;

        if (el.tagName === "SELECT") {
            el = el as HTMLSelectElement;
            data[name] = el?.options[el.selectedIndex]?.value;
        } else {
            el = el as HTMLInputElement;
            switch (el.type) {
                case "checkbox":
                    data[name] = el.checked;
                    break;
                case "date":
                    data[name] = new Date(el.value)
                    break;
                case "datetime-local":
                    data[name] = new Date(el.value)
                    break;
                default:
                    data[name] = el.value;
            }
        }
    }

    return data;
}

export function redirectWithPreference(url: string, saveInSession?: boolean) {
    const wants = localStorage.getItem("wantsUrl");
    if (saveInSession) {
        window.location.href = wants ?? url;
    } else {
        window.location.replace(wants ?? url);
    }
}

export function apiFetch(path: string, options?: RequestOptions & {query?: Dict}, isJson: boolean = true): Promise<Response> {
    let queryString;
    if (options?.query) {
        queryString = new URLSearchParams(options?.query).toString();
        delete options.query;
    }

    if (options?.method === "POST" && isJson) {
        const headers = new Headers(options.headers);

        if (!headers.has("Content-Type"))
            headers.set("Content-Type", "application/json; charset=utf-8");

        if (options.body && typeof options.body !== "string") options.body = JSON.stringify(options.body);
        options.headers = headers;
    }

    let url: string = process.env.REACT_APP_API_BASE + path;
    if (queryString) {
        url = `${url}?${queryString}`;
    }

    if (DEBUG) console.log(options?.method || "GET", url);

    return fetch(url, options as RequestInit);
}

export function printProfileTable(id: string, phase: ("mount" | "update" | "nested-update"), actualDuration: number, baseDuration: number, startTime: number, commitTime: number, interactions: Set<any>) {
    console.table({
        id: id,
        phase: phase,
        actualDuration: actualDuration,
        baseDuration: baseDuration,
        startTime: startTime,
        commitTime: commitTime,
        interactions: interactions
    })
}

export function groupArrByKey(data: Dict[], groupByKey: string | string[]) {
    const dict = {} as Dict<DataGroup>;

    for (const el of data) {
        let groupString: string;
        if (typeof groupByKey === "string") {
            groupString = el[groupByKey];
        } else {
            const arr: string[] = [];
            for (const key of groupByKey) {
                arr.push(el[key].toString());
            }
            groupString = arr.join(";");
        }

        if (!groupString) continue;

        const group = dict[groupString];
        if (!group) {
            dict[groupString] = {
                group: groupString,
                elements: [el]
            }
        } else {
            group.elements.push(el);
        }
    }

    return dict;
}

export function groupArrByKeys(data: Dict[], groupByKeys: string[]) {
    const dict = {} as Dict;

    for (const el of data) {
        let parent = dict;
        for (let i=0; i < groupByKeys.length; i++) {
            const groupByKey = groupByKeys[i];
            const groupString = el[groupByKey] as string;

            if (!groupString) continue;

            if (!parent[groupString]) {
                if (i === groupByKeys.length - 1) {
                    parent[groupString] = [el];
                } else {
                    parent[groupString] = {};
                }
            } else if (i === groupByKeys.length - 1) {
                parent[groupString].push(el);
            }

            parent = parent[groupString];
        }
    }

    return dict;
}
