import React from 'react';
import Item from "./Item";
import {GroupedItems} from "../Home";
import {Category as CategoryObj} from "../../../../server/routes/categories";
import {Item as ItemObj} from "../../../../server/routes/items";
import Icon from "@mdi/react";
import {mdiDelete} from "@mdi/js";
import {apiFetch} from "../../../js/util";
import {getString} from "../../../js/languages";

type Props = {
    category: CategoryObj,
    children: GroupedItems,
    useCategories: [CategoryObj[], React.Dispatch<React.SetStateAction<CategoryObj[]>>],
    useItems: [ItemObj[], React.Dispatch<React.SetStateAction<ItemObj[]>>]
};

const ItemCategory: React.FC<Props> = ({category, children, useCategories, useItems}) => {
    const [categories, setCategories] = useCategories;
    const [items] = useItems;

    const categoryItems = items.filter(i => i.category === category.id);
    if (categoryItems.length === 0) return null;

    const doDeleteCategory = (e: React.MouseEvent<HTMLSpanElement>) => {
        const {id, name} = category;
        const confirmed = window.confirm("Are you sure you want to delete the category '" + name + "'?");
        if (!confirmed) return;

        apiFetch("/categories/" + id, {method: "DELETE", credentials: "include"})
            .then(res => res.ok && setCategories(categories.filter(c => c.id !== category.id)))
            .catch(err => console.log(err));
    }

    return (
        <div className={"text-black break-inside-avoid mb-2 bg-white"}>
            <div className={"text-center py-2 border-b-2 border-gray-300 flex justify-between"}>
                <div/>
                <h1 className={"text-2xl font-bold text-gray-800"}>{category.name}</h1>
                <span className={"self-center mr-2 cursor-pointer"} onClick={doDeleteCategory}>
                    <Icon path={mdiDelete} size={1} title={"Delete Category"}/>
                </span>
            </div>
            <table className={"min-w-full divide-y divide-gray-200"}>
                <thead>
                <tr>
                    <th className={"px-6 py-3 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase w-3/4"}>
                        {getString("name")}
                    </th>
                    <th className={"px-6 py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase w-1/8"}>
                        {getString("amount")}
                    </th>
                    <th className={"px-6 py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase w-1/8"}>
                        {getString("actions")}
                    </th>
                </tr>
                </thead>

                <tbody className={"bg-white divide-y divide-gray-200"}>
                {Object.keys(children).map(item =>
                    <Item children={children[item]} key={item} useItems={useItems}/>
                )}
                </tbody>
            </table>
        </div>
    );
}

export default React.memo(ItemCategory);