import React, {useEffect} from 'react';

export type Message = [state: "info" | "success" | "error", message: string]

type Props = {
    msgState?: [Message | undefined, React.Dispatch<React.SetStateAction<Message | undefined>>],
    className?: string,
    hideAfter?: number
}

const MsgBox: React.FC<Props> = ({className, msgState, hideAfter}) => {
    const [msg, setMsg] = msgState || [];

    useEffect(() => {
        if (!msg || !hideAfter || !setMsg) return;

        setTimeout(() => setMsg(undefined), hideAfter);
    }, [msg, setMsg, hideAfter]);

    if (!msg) return null;

    let msgPrefix: string;
    switch (msg[0] || "info") {
        case "error":
            msgPrefix = "bg-error-light border-error"
            break;
        case "success":
            msgPrefix = "bg-success-light border-success"
            break;
        case "info":
            msgPrefix = "bg-background-secondary border-background-secondary-alt"
    }

    return (
        <div
            className={msgPrefix + (" text-txt-secondary border-l-4 mb-5 p-1 mx-auto w-fit px-2" + (className ? ` ${className}` : ""))}>
            {msg[1]}
        </div>
    );
};

export default MsgBox;