import React from 'react';
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import {getString} from "../../js/languages";

function Unauthorized() {
    return (
        <div id={"page"} className={"bg-background-primary"}>
            <Navbar/>

            <div id={"content"} className={"content-wrap p-5"}>
                <div className="mx-auto text-center bg-primary text-txt-primary px-3 py-5 rounded">
                    <h1>{getString("pageUnauthorized")}</h1>
                    <p>{getString("pageUnauthorizedDesc")}</p>

                    <br/>

                    <button onClick={() => {window.history.back()}} className={"bg-secondary rounded-md px-2 py-1"}>
                        {getString("backToLastLocation")}
                    </button>
                </div>
            </div>

            <Footer/>
        </div>
    );
}

export default Unauthorized;
