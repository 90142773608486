import React from 'react';
import Icon from "@mdi/react";
import {mdiLoading} from "@mdi/js";

interface Props {
    className?: string
}

const Loading: React.FC<Props> = ({className}) => {
    return (
        <Icon path={mdiLoading} className={"animate-spin size-24"} />
    );
};

export default Loading;