import React from 'react';
import {Item as ItemObj} from "../../../../server/routes/items";
import Icon from "@mdi/react";
import {mdiMinus, mdiPlus} from "@mdi/js";
import {getString} from "../../../js/languages";
import {apiFetch, dateFormat} from "../../../js/util";
import {useSearchParams} from "react-router-dom";

interface Props {
    children: ItemObj[],
    useItems: [ItemObj[], React.Dispatch<React.SetStateAction<ItemObj[]>>]
}

const Item: React.FC<Props> = ({children, useItems}) => {
    const [items, setItems] = useItems;
    const [,setSearchParams] = useSearchParams();

    const now = Date.now();
    const name = children[0].name;
    const category = children[0].category;
    const hasExpiredItems = children.some(v => v.expiry > 0 && now >= v.expiry);

    const onClick = () => {
        setSearchParams({item: name, category: category.toString()});
    };

    const addItemType = () => {
        const template = items.find(v => v.name === name);
        if (!template) return;

        const newItem = {...template, expiry: 0};
        apiFetch("/items", {method: "POST", body: newItem, credentials: "include"})
            .then(async (res) => {
                if (!res.ok) throw new Error(res.statusText);
                const data = await res.json();
                if (!data.success || !data.id) throw new Error("Error whilst quick adding item");

                newItem.id = data.id;
                setItems([...items, newItem]);
            })
            .catch(console.error);
    };

    const removeItemType = () => {
        const types = items.filter(v => v.name === name)
            .sort((a, b) => a.expiry - b.expiry);
        const toRemove = types[0];

        if (!toRemove) return;
        const date = toRemove.expiry > 0 ? dateFormat(new Date(toRemove.expiry)) : getString("na");
        const confirmation = window.confirm(getString("deleteItemConfirmation", toRemove.name, date));

        if (confirmation) {
            apiFetch("/items/" + toRemove.id, {method: "DELETE", credentials: "include"})
                .then(async (res) => {
                    if (!res.ok) throw new Error(res.statusText);
                    setItems(items.filter(v => v.id !== toRemove.id));
                })
                .catch(console.error);
        }
    };

    return (
        <tr className={hasExpiredItems ? "bg-yellow-100" : "bg-gray-200"}>
            <td className={"px-6 py-4 whitespace-no-wrap cursor-pointer"} onClick={onClick}>{name}</td>
            <td className={"px-6 py-4 whitespace-no-wrap text-center"}>{children.length}</td>
            <td className={"px-6 py-4 whitespace-no-wrap text-center flex justify-center"}>
                <span onClick={addItemType}>
                    <Icon path={mdiPlus} size={1} className={"cursor-pointer"}
                          title={getString("add")}/>
                </span>

                <span onClick={removeItemType}>
                    <Icon path={mdiMinus} size={1} className={"cursor-pointer"}
                          title={getString("delete")}/>
                </span>
            </td>
        </tr>
    );
}

export default Item;